/* PLANOS */
.page__plans-section {
    padding-top: 3rem;
    background-color: var(--cdm-principal);
}

.page__plans-container {
    display: flex;
    flex-direction: column;
}

.page__plans-text {
    color: var(--cdm-secundaria);
    margin: 2rem 1.5rem;
}

.page__plans-text h2 {
    font-weight: 500;
    font-size: 1.8rem;
    text-align: center;
    margin: 2rem 0;
}

.page__plans-text strong {
    font-weight: 700;
    color: var(--cdm-branco);
}

@media screen and (min-width: 768px) {
    .page__plans-section {
        padding: 6rem 0 0 0;
    }
}

@media screen and (min-width: 1025px) {
    .page__plans-section {
        padding: 7rem 0 0 0;
    }
}

@media screen and (min-width: 1440px) {
    .page__plans-section {
        padding: 10rem 0 0 0;
    }
}