/* PAGE ERROR */
.page__error {
    background-color: var(--cdm-branco);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    text-align: center;
    align-items: center;
    padding: 0 2rem;
    margin: 0 auto;
    gap: 1rem;
}

.page__error h2 {
 font-size: 1.5rem;
 font-weight: 700;
 line-height: 1.3;
 color: var(--cdm-secundaria);
}

.page__error span {
    font-size: 2.5rem;
}

.div-separate {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.style-div-separate::before,
.style-div-separate::after {
    content: '';
    display: flex;
    width: 5rem;
    height: 0.15rem;
    margin: 0 auto;
    background-color: var(--cdm-secundaria);
}

.page__error p {
    color: var(--cdm-secundaria);
    margin-bottom: 1rem;
}

.page__error p a {
    display: block;
    text-decoration: none;
    color: var(--cdm-secundaria);
    background-color: var(--cdm-principal);
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem;
    border: 0.1rem solid var(--cdm-secundaria);
    border-radius: 0.7rem;
}

/* HOVER */

.page__error p a:hover {
    transform: scale(1.1);
    color: var(--cdm-branco);
    background-color: var(--cdm-secundaria);
    border: 0.1rem solid var(--cdm-principal);
}