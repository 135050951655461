 /* FORMULARIO */

 .confirmation__container {
    background-color: var(--cdm-branco);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.form-confirmation {
    text-align: center;
    padding: 0 2rem;
    margin: 0 auto;
}

.form-confirmation__title {
    font-size: 1.7rem;
    color: var(--cdm-form-confirmation);
    margin-bottom: 1.5rem;
}

.form-confirmation__message {
    font-size: 1.1rem;
    color: var(--cdm-preto);
    margin-bottom: 1.5rem;
}

.form-confirmation__details {
    font-size: 1rem;
    color: var(--cdm-cinza);
    margin-bottom: 2rem;
}

.form-confirmation__link {
    color: var(--cdm-principal);
    text-decoration: none;
}

.form-confirmation__link:hover {
    text-decoration: underline;
}

.form-confirmation__button {
    background-color: var(--cdm-form-confirmation);
    color: var(--cdm-branco);
    border: none;
    padding: 1rem 1.2rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;
}

.form-confirmation__button:hover {
    background-color: var(--cdm-form-confirmation);
}

@media screen and (min-width: 768px) {
    .form-confirmation {
        padding: 0 5rem;
    }

    .form-confirmation__title {
        font-size: 2rem;
    }

    .form-confirmation__message {
        font-size: 1.25rem;
    }

    .form-confirmation__details {
        font-size: 1.15rem;
    }

    .form-confirmation__button {
        font-size: 1.15rem;
    }
}

@media screen and (min-width: 1025px) {
    .confirmation__container {
        height: 90.3vh;
    }

    .form-confirmation {
        padding: 0 10rem;
    }
}

@media screen and (min-width: 1440px) {
    .confirmation__container {
        height: 91.15vh;
    }

    .form-confirmation {
        padding: 0 30rem;
    }
}