/* SOBRE SANTA SAUDE */

.about__info {}

.about__info__text {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 2rem;
}

.about__subtitle {
    font-size: 1.23rem;
    color: var(--cdm-principal);
}

.about__title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--cdm-preto);
    margin-bottom: 1rem;
    text-decoration: underline;
    text-decoration-color: var(--cdm-principal);
    text-underline-offset: 0.2rem;
    line-height: 1.6rem;
}

.about__paragraph {
    color: var(--cdm-cinza);
    line-height: 1.2rem;
}

.about__info img {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    object-fit: contain;
    box-shadow: var(--cdm-shadow);
    object-position: bottom;
}

@media screen and (min-width: 768px) {
    .about__info img {
        height: 300px;
        object-fit: cover;
        object-position: bottom;
    }
}

@media screen and (min-width: 1025px) {
    .about__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-image: url('../../../../assets/img-main/unidadesantasaude.png');
        background-size: cover;
        background-position: center;
        position: relative;
        border-radius: 10px;
        margin: 2rem 3rem;
    }

    .about__info img {
        display: none;
    }

    .about__subtitle {
        font-size: 1.3rem;
    }

    .about__title {
        font-size: 1.8rem;
        text-underline-offset: 0.3rem;
        line-height: 2rem;
    }
}

@media screen and (min-width: 1440px) {
    .about__info {
        position: relative;
        border-radius: 10px;
        margin: 2rem 15rem;
    }

    .about__subtitle {
        font-size: 1.5rem;
    }

    .about__title {
        font-size: 2rem;
        text-underline-offset: 0.4rem;
        line-height: 2.3rem;
    }
}
