/* FOOTER */
.footer {
    background-color: var(--cdm-secundaria);
    position: relative; 
    width: 100%;
    align-items: center;
    padding: 1rem 0;
    box-shadow: var(--cdm-shadow-acima);
    z-index: 999;
}

.redes__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.2rem 0;
    align-items: center;
}

.redes__itens {
    display: flex;
    gap: 0.3rem;
}

.ans__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
}

.footer__logo__desktop {
    width: 7.5rem;
}

.footer__ans {
    width: 7rem;
}


.redes__item img {
    width: 2rem;
    height: 2rem;
}

.copyright {
    text-align: center;
    font-size: 0.7rem;
    color: var(--cdm-branco);
    padding: 0.2rem 0 0.5rem 0;
    margin: 0rem 1rem;
    line-height: normal;
}

.copyright p a {
    text-decoration: none;
}

.copyright span {
    color: var(--cdm-branco);
    text-decoration: none;
    font-weight: 700;
}

@media screen and (min-width: 1025px) {
    .footer {
        background-color: var(--cdm-secundaria);
        padding: 0.5rem 0;
    }

    .footer__logo__desktop,
    .footer__ans {
        width: 8.5rem;
    }

    .redes__itens {
        display: flex;
        gap: 0.7rem;
    }

    .redes__item img:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
    }

    .copyright {
        padding: 0;
        margin: 0;
        line-height: normal;
    }

}