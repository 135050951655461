/* Estilos comuns */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1003;
  background-image: url('../../assets/img-header/bg-header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: var(--cdm-shadow);
}

.logo__container {
  display: flex;
  align-items: center;
}

.logo__container p {
  display: none;
}

.header__logo {
  height: auto;
  width: 5.5rem;
}

.menu-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--cdm-branco);
}

.menu-button img {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  filter: invert(100%) hue-rotate(180deg);
}

.menu__nav {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 3.4rem;
  right: 1.5rem;
  z-index: 1000;
}

.menu__nav.open {
  display: flex;
}

.menu__ul {
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: var(--cdm-shadow);
}

.menu__li {
  background-color: var(--cdm-branco);
  color: var(--cdm-secundaria);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header__link {
  display: block;
  text-decoration: none;
  color: var(--cdm-secundaria);
  max-width: 15rem;
  padding: 1rem;
  transition: color 0.3s ease;
}

/* HOVER */
.menu-button:hover {
  font-weight: 700;
  text-decoration: overline;
  text-decoration-color: var(--cdm-principal);
}

.menu__li:hover,
.hover:hover {
  background-color: var(--cdm-secundaria);
  color: var(--cdm-branco);
}

.header__link.active,
.header__link:hover {
  font-weight: 700;
  text-decoration: overline;
  text-decoration-color: var(--cdm-principal);
  color: var(--cdm-branco);
  background-color: var(--cdm-secundaria);
}


@media screen and (min-width: 768px) {
  .header {
    padding: 1rem 1.5rem;
  }

  .logo__container {
    gap: 1rem;
  }

  .logo__container p {
    display: block;
    color: var(--cdm-secundaria);
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1;
  }

  .logo__container p span {
    color: var(--cdm-principal);
    font-weight: 700;
  }

  .header__logo {
    width: 7.5rem;
  }

  .menu-button {
    font-size: 1.3rem;
  }

  .menu-button img {
    width: 2rem;
    height: 2rem;
  }

  .menu__nav {
    top: 5rem;
    right: 1.5rem;
  }

  .header__link {
    max-width: 15rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }
}


@media screen and (min-width: 1025px) {
  .header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 4rem;
  }

  .logo__container p {
    font-size: 1.3rem;
    line-height: 1.1;
  }

  .header__logo {
    width: 8rem;
  }

  .menu-button {
    display: none;
  }

  .menu__nav {
    display: flex;
    position: static;
    justify-content: flex-end;
    flex-direction: row;
  }

  .menu__ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2rem;
    row-gap: 1rem;
    box-shadow: none;
  }

  .menu__li {
    background-color: transparent;
  }

  .header__link {
    color: var(--cdm-branco);
    padding: 0;
    max-width: none;
  }
}

@media screen and (min-width: 1440px) {
  .header {
    padding: 1rem 15rem;
  }

  .header__logo {
    width: 10rem;
  }

}