/* COBERTURA */

.cobertura__container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
}

.cobertura__titulo {
    margin: 0 2rem 2rem 2rem;
}

.cobertura__titulo h2 {
    color: var(--cdm-secundaria);
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    text-decoration-color: var(--cdm-principal);
    text-align: center;
    line-height: 1.8rem;
}

.rede__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2rem;
    gap: 2rem;
    justify-content: center;
}

.redes {
    box-shadow: var(--cdm-border-colored);
    border-radius: 0.5rem;
    padding: 1rem 0;
    flex: 1 1 200px; 
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.redes__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.redes__info img {
    max-width: 15%;
}

.redes__info p {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.redes__info-texto {
    text-align: center;
    line-height: 1.1;
}

.redes__info-texto p:first-of-type {
    font-weight: 500;
    font-size: 1rem;
}

.redes__info-texto p {
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
}

@media screen and (min-width: 768px) {
    .cobertura__titulo h2 {
        font-size: 1.7rem;
        text-underline-offset: 0.4rem;
    }

    .redes__info img {
        max-width: 15%;
    }

    .redes__info p {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .redes__info-texto {
        text-align: center;
        line-height: 1.1;
    }

    .redes__info-texto p:first-of-type {
        font-weight: 500;
        font-size: 1rem;
    }

    .redes__info-texto p {
        font-size: 0.9rem;
        margin-bottom: 0.3rem;
    }
}

@media screen and (min-width: 1025px) {
    .cobertura__titulo h2 {
        font-size: 2.3rem;
        text-underline-offset: 0.4rem;
        margin: 1rem 0 1rem 0;
    }

    .redes {
        flex: 1 1 30%;
    }
}

@media screen and (min-width: 1440px) {
    .redes {
        flex: 1 1 22%;
    }
}
