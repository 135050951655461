/* FORMULARIO */

.form__container {
  margin: 2rem 1rem;
}

.form__title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--cdm-secundaria);
  text-align: center;
  text-decoration: underline;
  text-decoration-color: var(--cdm-principal);
  text-underline-offset: 0.2rem;
}

.contact__form {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
}

.input__group {
  display: flex;
  flex-direction: column;
  color: var(--cdm-secundaria);
  font-size: 1rem;
  font-weight: 500;
  gap: 0.6rem;
  padding: 0.5rem 0;
  width: 100%;
  margin-bottom: 0.7rem;
}

.input__group span {
  color: var(--cdm-vermelho);
}

.input:not(:first-child),
.button {
  height: 2rem;
  border-radius: 0.3rem;
  border: 0.15rem solid var(--cdm-principal);
  padding-left: 0.8rem;
  font-size: 1rem;
}

.button {
  cursor: pointer;
  background-color: var(--cdm-principal);
  font-size: 1rem;
  transition: background-color, transform 0.8s;
  margin-top: 1rem;
  width: 100%;
  color: var(--cdm-branco);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0.5rem;
}

.declaracao {
  color: var(--cdm-preto);
  font-weight: 400;
  font-size: 0.7rem;
  margin: 0 2rem;
}

.declaracao span {
  font-weight: 700;
}

/* HOVER */
.button:hover {
  background-color: var(--cdm-botao-hover);
  transform: scale(1.01);
  color: var(--cdm-secundaria);
}



@media screen and (min-width: 768px) {
  .form__title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .contact__form {
    padding: 2rem 3rem;
    background-color: var(--cdm-terciaria);
    justify-content: space-between;
    border: 0;
    border-radius: 1em;
    padding: 1.25rem;
  }

  .input__group {
    font-size: 1.1rem;
    color: var(--cdm-branco);
    gap: 0.5rem;
    padding: 0.5rem 0;
    width: 47.5%;
  }

  .input:not(:first-child),
  .button {
    font-size: 1.1rem;
    height: 2.5rem;
    border-radius: 4px;
    border: 0;
    padding-left: 0.8rem;
    font-size: 1rem;
  }

  .input__group:first-of-type,
  .input__group:last-of-type {
    width: 100%;
    padding-top: 0;
  }

  .input__group:last-of-type {
    padding-top: 0.5rem;
  }

  .declaracao {
    font-size: 0.8rem;
    margin: 0 3rem;
  }
}

@media screen and (min-width: 1025px) {
  .form__title {
    color: var(--cdm-branco);
  }

  .input__group {
    padding: 0.2rem 0;
  }
  
  .declaracao {
    color: var(--cdm-branco);
    margin: 1rem;
  }
}

@media screen and (min-width: 1440px) {
 
}