/* HOME */
.home {
    margin-top: 3.2rem;
}

@media screen and (min-width: 768px) {
    .home {
        margin-top: 4.8rem;
    }
}

@media screen and (min-width: 1025px) {
    .home {
        margin-top: 4.8rem;
    }
}

@media screen and (min-width: 1440px) {
    .home {
        margin-top: 5.9rem;
    }
}