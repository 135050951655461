/* DÚVIDAS */

.questions {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    background-color: var(--cdm-branco);
}

.questions__title {
    width: 100%;
    padding: 2rem 0 1rem 0;
    background-color: var(--cdm-secundaria);
}

.questions__title h3 {
    color: var(--cdm-branco);
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    line-height: 2rem;
    text-decoration-color: var(--cdm-principal);
    text-align: center;
    margin: 0 2rem;
}

.questions__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 1rem;
}

.questions__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 3px 3px 0px 0px var(--cdm-secundaria);
    border-radius: 1rem;
    padding: 1rem;
    max-width: 35%;
    justify-content: center;
}


.questions__item img {
    width: 40%;
}

.questions__item p {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

@media screen and (min-width: 768px) {

    .questions__title h3 {
        font-size: 1.5rem;
    }

    .questions__item {
        max-width: 20%;
    }

    .questions__item p {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 1025px) {
    .questions__container {
        padding: 3rem 3rem;
    }

    .questions__title h3 {
        font-size: 1.8rem;
    }

    .questions__item {
        max-width: 20%;
    }

    .questions__item p {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 1440px) {
    .questions__container {
        margin: 0rem 15rem 2rem 15rem;
    }

    .questions__title h3 {
        font-size: 2rem;
    }

    .questions__item {
        max-width: 20%;
    }

    .questions__item p {
        font-size: 1.2rem;
    }
}