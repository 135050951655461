/* CONTATO */

.contact {
  margin-top: 3.9rem;
}

.contact__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__info img {
  max-width: 300px;
  margin-bottom: 0.7rem;
}

.contact__subtitle {
  font-size: 1rem;
  font-weight: 700;
  color: var(--cdm-secundaria);
  margin-bottom: 0.7rem;
}

.contact__p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .contact {
    margin-top: 5rem;
  }

}

@media screen and (min-width: 1025px) {
  .contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    padding: 0 4rem;
    margin: 0;
    height: 100vh;
    box-sizing: border-box;
    background-image: url(../../../assets/img-cliente/bg-cliente.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  
  .contact__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 30em;
    align-items: initial;
  }
  
  .contact__info img {
    max-width: 200px;
    margin-bottom: 0;
  }
  
  .contact__subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--cdm-secundaria);
    margin-bottom: 0.7rem;
  }
  
  .contact__p,
  .form__container {
    font-size: 1.313rem;
  }

}

@media screen and (min-width: 1440px) {
  .contact {
    padding: 0 15rem;
  }
  
  .contact__info {
    margin-bottom: 28.5em;
  }
  
  .contact__subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--cdm-secundaria);
    margin-bottom: 0.7rem;
  }
  
  .contact__p,
  .form__container {
    font-size: 1.313rem;
  }
}