@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
    /* COLORS */
    --cdm-branco: #F2F2F2;
    --cdm-cinza-shadow: #c8c9cd;
    --cdm-preto: #0D0D0D;
    --cdm-cinza: #666;
    --cdm-cinza-claro: #495964;
    --cdm-principal: #0ca0f2;
    --cdm-secundaria: #1b3285;
    --cdm-terciaria: #10193a;
    --cdm-vermelho: red;
    --cdm-botao-hover: #0fe3ff;
    --cdm-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
    --cdm-shadow-acima: 0px -5px 6px rgba(0, 0, 0, 0.1);
    --cdm-wpp-title-dark: #23a21c;
    --cdm-wpp-title-light: #8ff787;
    --cdm-wpp-gradient: linear-gradient(45deg, var(--cdm-wpp-title-dark), var(--cdm-wpp-title-light));
    --cdm-wpp-gradient-invert: linear-gradient(45deg, var(--cdm-wpp-title-light), var(--cdm-wpp-title-dark));
    --cdm-border-colored: 3px 3px 0px 0px var(--cdm-secundaria);
    --cdm-form-confirmation: #4CAF50;
    --cdm-botao-wpp: #25d366;
    --cdm-bg-benefits: #d5effd;

    /* FONTES */
    --fonte-principal: "Roboto", sans-serif;
}

body {

    font-family: var(--fonte-principal);
    color: var(--cdm-preto);
    font-size: 1em;
    overflow-x: hidden;

}